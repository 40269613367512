



















































import { NotificationMixin } from '../mixins';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  name: 'ForgotPassword',
})
export default class ForgotPassword extends Mixins(NotificationMixin) {
  user = {
    username: '',
  };

  submit() {
    const { username } = this.user;
    return this.$store
      .dispatch('auth/forgot', { username })
      .then(() => {
        this.notifySuccess('Please check your email.');
        this.$router.push({ name: 'login' });
      })
      .catch((err) => {
        const errMessage = err.message || 'Something went wrong.';
        this.notifyError(errMessage);
      });
  }
}
